.user-main {
    margin: 0.4rem;
    padding: 0.4rem;
    border-radius: 8px;
    box-shadow: var(--shadow);
}
.u-profile {
    background: #fff;
}
.u-profile > div {
    position: relative;
}
.deets {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.deets > div {
    grid-column: span 1;
    text-align: left;
}
.deets > img {
    grid-column: span 1;
    width: 100%;
}
.deets > div > h1 {
    font-weight: 400;
    font-size: 1.6rem;
}
.deets > div > p {
    font-weight: 300;
    font-size: 0.9rem;
    margin-bottom: 0;
}
.deets > div > p > svg {
    color: #aaa;
    margin-right: 0.4rem;
    font-size: 1.2rem;
}
.deets > div > p > span {
    font-weight: 500;
}
.deets > div > span {
    font-size: 0.8rem;
    color: #aaa
}
.deets > div > h5 {
    font-weight: 400;
}
.deets > div > h5 > span  {
    font-size: 0.8rem;
    font-weight: 300;
}
.deets > div > h4 {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 0.4rem;
}
.deets > div > .member {
    position: relative;
}
.deets > div > .member > svg {
    font-size: 1.8rem;
    position: relative;
    color: var(--main);

}
.deets > div > .member > span {
    position: absolute;
    z-index: 10;
    color: #fff;
    top: 0.2rem;
    left: 0.3rem;
    font-weight: 400;
}
.u-profile .u-brief {
    padding: 0.8rem 0.4rem;
}
.u-profile .u-brief:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    height: 1px;
    background-color: #ddd;
}
.u-profile .u-brief > h5 {
    text-align: left;
    font-weight: 400;
    color: #999;
    display: none;
}
.u-profile .u-brief > p {
    text-align: left;
    font-size: 0.9rem;
}
.u-profile .u-portfolio {
    margin-top: 1rem;
}
/* .u-profile .u-portfolio > div:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 250px;
    width: 90%;
    margin: 0 auto;
} */
.u-profile .u-portfolio > .port-wrap {
    width: 90%;
    margin: 0 auto;
    position: relative;
}
.u-profile .u-portfolio > .port-wrap > img {
    width: 100%;
}
.u-profile .u-portfolio > .port-wrap > .gallery {
    height: 80px;
    white-space: nowrap;
    overflow-x: scroll;
    margin-top: -0.6rem;
}
.u-profile .u-portfolio > .port-wrap > .gallery > img {
    height: 90%;
    margin: 0 0.2rem;
}
.u-profile > div > h5 {
    color: #aaa;
    font-weight: 400;
    text-align: left;
    margin: 1rem 0 0.6rem
}
.u-profile .u-portfolio > div > img {
    grid-column: span 1;
    width: 92%;
    margin: 0.4rem;
}
.u-profile .u-contact-deets > div {
    background: var(--bg-color);
    margin: 0.6rem auto;
    width: 80%;
    padding: 0.4rem 0;
    border-radius: 8px;
    transition: 0.2s;
}
.u-profile .u-contact-deets > div:hover {
    box-shadow: var(--shadow-alt);
    transition: 0.2s;
}
.u-profile .u-contact-deets > div > p {
    text-align: left;
    color: #444
}
.u-profile .u-contact-deets > div > svg {
    margin: 0.3rem 0.6rem;
    float: left;
    color: #ccc
}
.u-profile .u-reviews > .review {
    background: var(--bg-color);
    padding: 0.4rem 0;
    border-radius: 8px;
    margin: 0.6rem auto;
    border: 1px solid rgba(0,0,0,0.1);
}
.u-profile .stars {
    min-height: 20px;
    padding-left: 0.4rem;
}
.u-profile .stars > svg {
    color: #e2e2e2;
    float: left;
    margin: 0 0.2rem;
}
.u-profile .stars > .bright {
    color: var(--yellow)
}
.u-profile .u-reviews div > h5 {
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin: 0.4rem;
}
.u-profile .u-reviews div > p {
    text-align: left;
    font-weight: 300;
    font-size: 0.9rem;
    margin: 0.6rem 0.6rem 0;
}
.u-profile .u-reviews > .review button {
    font-size: 0.9rem;
    margin: 0.8rem 0.4rem;
    padding: 0.4rem;
    border-radius: 4px;
    float: left;
}
.u-profile .u-reviews > .review button > svg {
    margin: 0 0.4rem;
    color: #999
}
.u-profile .u-reviews > .review button > span {
    font-size: 0.8rem;
    margin-left: 0.4rem;
}
.u-profile .u-reviews > .review .btn-clear {
    background: none;
    color: var(--secondary);
    float: right;
}
.u-profile .u-reviews .review-btns {
    height: 50px;
}
.rating-block {
    border: 4px solid var(--bg-color);
    width: fit-content;
    border-radius: 8px;
    padding: 0.2rem
}
.rating-block h5 {
    background: var(--red);
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 40px;
    padding: 0.4rem;
    border-radius: 8px 0 0 8px;
    font-weight: 300;
}
.rating-block > div {
    display: inline-block;
    vertical-align: middle;
}
.rating-block > div > svg {
    font-size: 1.4rem;
}
.rate-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 85px 85px;
    margin: 0.4rem 0 0;
}
.rate-circle {
    grid-column: span 1;
    position: relative;
    width: 100%;
    height: 100%;
    color:#777;
    display: inline-block;
    margin: 0 auto 0.4rem;
    z-index: 10;
}
.rate-circle > span {
    position: absolute;
    font-size: 1.6rem;
    z-index: 10;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    border: 2px solid #777;
}
.rate-circle > p {
    position: absolute;
    width: 100%;
    height: fit-content;
    text-align: center;
    left: 0;
    bottom: -0.4rem;
    right: 0;
    margin: auto;
    z-index: 10;
    font-size: 0.9rem;
}
.rate-circle > span:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgba(153, 205, 113, 0.4);
    bottom: -4px;
    right: -4px;
    z-index: -1;
}
.u-profile .u-services > .service {
    position: relative;
    text-align: left;
    background: var(--bg-color);
    margin: 0.6rem auto;
    border-radius: 8px;
    border: 1px solid rgba(0,0,0,0.1);
    width: 80%;
    padding-right: 1rem;
}
.u-profile .u-services > .service > span,.u-profile .u-services > .service > p  {
    display: inline-block;
}
.u-profile .u-services > .service > span {
    width: 50px;
    text-align: center;
    position: relative;
    font-size: 2rem;
    color: #fff;
    /* border: 1px solid rgba(0,0,0,0.1); */
    background: #777;
    border-radius: 8px 0 0 8px;
    margin-right: 1rem;
}
.u-profile .u-services > .service > p {
    font-size: 1rem;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4rem;
    margin: auto;
    width: fit-content;
    height: fit-content;
    color: #777;
}
.u-socials .socials {
    display: flex;
}
.u-socials .socials > svg {
    flex: 1;
    font-size: 2rem;
    color:#555;
    transition: 0.2s;
}
.u-socials .socials > svg:hover {
    transition: 0.2s;
    color: var(--main)
}