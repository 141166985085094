form > div {
    padding: 0.4rem 0.8rem
}
form > div .form-label, form > div .form-check-label {
    text-align: left;
    font-size: 0.9rem;
}
form > div .form-text {
    font-size: 0.8rem;
    text-align: left;
}
form > div .form-control {
    font-size: 0.9rem;
}