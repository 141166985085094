.company-wrap {
    position: relative;
    min-height: 120px;
}
.company-wrap > div > img {
    width: 90%;
}
.company-wrap > div > h5 {
    font-weight: 400;
    font-size: 0.9rem;
}
.company-wrap > div > p {
    font-weight: 300;
    font-size: 0.8rem;
    color: #777
}
.chevron {
    position: absolute;
    height: 80px;
    top: 0;
    bottom: 0;
    width: fit-content;
    margin: auto;
    filter: invert(85%);
}
.chevron-l { 
    left: 0
}
.chevron-r {
    transform: rotate(180deg);
    right: 0;
} 