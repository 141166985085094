.page {
    padding: 0.4rem 80px;
    position: relative;
}
.page > .page-deets > div {
    margin: 1rem auto;
    width: 50%;
    position: relative;
    padding-bottom: 3rem;
}
.page > .page-deets > div:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: rgba(0,0,0,0.2);
}
.page > .page-deets > div > h2 {
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 1rem;
}
.page > .page-deets > div > p {
    font-size: 1rem;
    font-weight: 300;
}
.about-us .staff-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-height: 500px;
    width: 65%;
    margin: 0 auto;
}
.about-us .staff-box > div {
    grid-column: span 1;
    grid-row: span 1;
    margin: 0.4rem;
    background: #fff;
    box-shadow: var(--shadow);
    border-radius: 4px;
}
.about-us .staff-box > div > h1 {
    font-weight: 400;
    font-size: 1.1rem;
    margin: 0.4rem 0 0.2rem;
}
.about-us .staff-box > div > h2 {
    font-weight: 300;
    font-size: 0.9rem;
}
.about-us .staff-box > div > img {
    display: block;
    margin: 1rem auto 0;
    height: 150px;
}
.about-us .staff-box > div > p {
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0.6rem 0
}
.page .page-join-us > div {
    padding-bottom: 2rem;
}
.page .page-join-us > div > button {
    margin-top: 1rem;
}
.page .watermark {
    position: absolute;
    width: 300px;
    filter: grayscale(100%);
    opacity: 0.2;
}
.page .on-the-right {
    right: 0;
    top: 10rem;
    transform: scaleX(-1);
}
.page .on-the-left {
    left: 0;
    bottom: 10rem;
}