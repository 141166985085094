.sidescroll {
    min-height: 150px;
}
.sidescroll > h1 {
    font-weight: 500;
    text-align: left;
    margin: 0.2rem 0.4rem;
}
.sidescroll > div {
    padding: 0.4rem;
    white-space: nowrap;
    overflow-x: scroll;
}
.sidescroll > div > div {
    background-color: #fff;
    box-shadow: var(--shadow);
    height: max-content;
    width: 140px;
    border-radius: 4px;
    margin: 0.2rem 0.6rem;
    padding: 0.4rem 0;
    display: inline-block;
}
.sidescroll > div > div > h5 {
    font-weight: 400;
    font-size: 0.9rem;
}