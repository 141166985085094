.cat-wrap {
    position: relative;
    min-height: 120px;
}
.cat-wrap > div {
    background-color: var(--light) !important;
    box-shadow: none !important;
}
.cat-wrap > div > img {
    height: 100px;
    margin: 0 auto 0.4rem;
    display: block;
    box-shadow: var(--shadow);
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    background: #fff;
}