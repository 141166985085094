.mobilemenu {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%; */
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 100;
    overflow: hidden;
    /* display: none; */
}
.mm-true {
    width: 100%;
}
.mm-false {
    width: 0
}
.mobilemenu > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: #fff;
    box-shadow: var(--shadow);
}
.mm-head {
    min-height: 65px;
}
.mm-head > img {
    width: 180px;
    margin: 0.4rem auto;
    /* float: right; */
}
.mm-head > svg {
    float: right;
    margin: 0.8rem;
    font-size: 1.6rem;
}
.mm-search {
    position: relative;
}
.mm-search > input {
    width: 90%;
    border-color: #f2f2f2;
    font-size: 1rem;
}
.mm-search > input::placeholder {
    color: #e2e2e2
}
.mm-search > svg {
    color: #d2d2d2;
    position: absolute;
    right: 28px;
    top: 18px
}
.mm-tools {
    display: flex;
    margin: 0.6rem 0 0.4rem;
}
.mm-tools > div {
    flex: 1;
    margin: 0.4rem 0.2rem
}
.mm-tools > div > svg {
    font-size: 2.4rem;
    color: #d9d9d9
}
.mm-tools > div > p {
    font-size: 0.9rem;
    color: #d9d9d9
}
.mm-tools > div:hover > svg {
    color: var(--main)
}
.mm-tools > div:hover > p {
    color: #666
}
.mm-menu a {
    text-align: left;
    padding: 0.8rem;
    transition: 0.2s;
    display: block;
    color: #a9a9a9
}
.mm-menu a > svg {
    margin-right: 0.6rem;
    color: #a9a9a9
}
.mm-menu a:hover {
    background: #f4f4f4 ;
    transition: 0.2s;
    color: #3d3d3d
}
.mm-menu a:hover > svg {
    color: var(--main)
}
.mm-sign {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: fit-content;
}
.mm-sign > button:first-child {
    float: left;
    background: none;
    font-size: 0.9rem;
    min-width: 50px;
    margin: 0.4rem;
    border-radius: 0.2rem;
    padding: 0.3rem 0.6rem;
    cursor: pointer;
    text-decoration: none;
}
.mm-sign > button > svg {
    font-size: 1.4rem;
    margin: 0 0.4rem 0;
}
.mm-sign > .btn-2 {
    float: right;
}