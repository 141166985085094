.breadcrumb {
    margin-bottom: 0;
}
.breadcrumb .pill {
    font-size: 0.9rem;
    border-radius: 50px;
    min-width: 40px;
    background: #fff;
    padding: 0.2rem 1.4rem 0.2rem 0.8rem;
    margin: 0.6rem 0.2rem;
    float: left;
    min-width: 25px;
    position: relative;
    /* box-shadow: var(--shadow); */
}
.breadcrumb .pill > svg {
    float: right;
    margin-right: 0.2rem;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0.4rem;
    margin: auto;
}
.search-head .mm-search input {
    background-color: #fff;
    border-radius: 8px;
    padding: 0.4rem 1rem;
}
.search-head .mm-search > svg {
    top: 20px
}
.search-body > .s-box {
    margin: 1.4rem 0.8rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: var(--shadow);
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: inherit
}
.search-body > .s-box > img {
    width: 100%;
    grid-column: span 1;
}
.search-body > .s-box > div {
    grid-column: span 1;
    text-align: left;
    padding: 0.4rem
}
.search-body > .s-box > div > h1, .search-body > .s-box > div > h3 {
    font-weight: 400;
    font-size: 1rem;
    padding: 0 0 0.2rem;
}
.search-body > .s-box > div > h2 {
    font-weight: 300;
    font-size: 0.8rem;
    padding: 0 0 0.2rem;
}
.search-body > .s-box > div > h2 > svg {
    margin-right: 0.2rem;
}
.search-body > .s-box > div > h2 > span {
    margin-left: 0.4rem;
    float: right;
}
.search-body > .s-box > div > h3 {
    color: var(--main);
}
.search-body > .s-box > div > h3 > span {
    font-size: 0.8rem;
    float: right;
}
.search-body > .s-box > div > p {
    font-size: 0.8rem;
    font-weight: 300;
}