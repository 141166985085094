.head {
    background:#fff;
    display: flex;
}
.head > div:first-child > svg {
    position: relative;
    z-index: 1000;
}
.head > div {
    flex: 1;
    align-self: center;
    padding: 0.4rem 0;
}
.head .logo {
    flex: 2;
    cursor: pointer;
}
.head .logo > img {
    width: 100%;
    vertical-align: middle;
}
.head .user {
    flex: 2
}
/* .head .user > svg {
    margin-left: 0.6rem
} */
.head .user .display-name {
    font-size: 1.2rem;
    width: 40px;
    height: 40px;
    /* background: linear-gradient( red, var(--main)); */
    background: var(--highlight);
    box-shadow: var(--shadow);
    color: #fff;
    border-radius: 50%;
    float: right;
    margin-right: 1rem;
    border: 2px solid rgba(0,0,0,0.1);
    line-height: 2.2rem;
}