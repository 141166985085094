.cat-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.cat-grid > a {
    grid-row: span 1;
    grid-column: span 1;
    margin: 0.6rem;
    border-radius: 6px;
    padding: 0.2rem;
    transition: 0.2s;
}
.cat-grid > a:hover {
    background: #fff;
    box-shadow: var(--shadow);
    transition: 0.2s;
}
.cat-grid > a > img {
    width: 100%;
    box-shadow: var(--shadow);
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    background: #fff;
}
.cat-grid > a:hover > img {
    box-shadow: none;
}
.cat-grid > a > h2 {
    font-size: 0.9rem ;
    font-weight: 300;
    margin: 0 0 0.2rem;
    color: #3d3d3d;
}
.cat-main > button {
    padding: 0.8rem 0.6rem;
    margin-top: 0.8rem
}
.cat-view {
    position: relative;
}
.cat-view > a {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem
}
.cat-view > div {
    padding-top: 1rem;
}
.cat-view > div > h1 {
    font-weight: 400;
}
