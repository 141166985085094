.head-banner {
    position: relative;
    margin: 0.6rem 0
}
.head-banner:after{
    content: "";
    position: absolute;
    bottom: -0.6rem;
    left: 0;
    right: 0;
    width: 80%;
    height: 3px;
    background: #ddd;
    margin: auto;
    border-radius: 50px;
}
.head-banner > svg {
    color: #ccc;
    font-size: 3rem;
    position: relative;
}
.head-banner:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 4rem;
    height: 4rem;
    border: 3px solid #ccc;
    border-radius: 50%;
}