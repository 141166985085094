.menu-container {
    display: none;
    padding: 4px 80px;
    background: #fff;
}
.menu-container > a {
    flex: 1;
    color: var(--text);
    font-size: 0.9rem;
    /* transition: 0.2s; */
}
.menu-container > a > svg {
    /* padding-right: 1rem; */
    /* display: none; */
    display: block;
    text-align: center;
    width: 100%;
}
.menu-container > a:hover {
    color: var(--main);
    /* transition: 0.2s; */
}
