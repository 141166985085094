.foot {
    /* min-height: 150px; */
    background: var(--dark-alt);
    margin-top: 0.6rem;
    color: #fff
}
.foot > img {
    width: 150px;
    margin: 1rem 0;
}
.foot > p {
    font-size: 0.8rem;
    color: #bbb;
    padding-bottom: 0.4rem;
}
.foot > div {
    padding-top: 0.8rem
}
.foot > div > a {
    font-size: 0.9rem;
    padding: 0.2rem 0;
    display: block;
    color: #e9e9e9
}