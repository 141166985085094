@media only screen and (min-width: 650px) {
    .head {
        height: 60px;
        padding: 0 80px;
    }
    .head .mobile-menu {
        display: none;
    }
    .head .logo {
        flex: 1;
    }
    .head .logo > img {
        width: 190px;
        float: left;
    }
    .head .menu-container {
        display: flex;
    }
    .head .user {
        flex: 1
    }
    .banner {
        height: 500px;
        overflow: hidden;
    }
    .banner .cta {
        left: 10rem;
        right:unset ;
        width: 40%;
    }
    .banner .cta > h1 {
        font-size: 3rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    .banner .cta > p {
        font-size: 1.4rem;
        font-weight: 300;
        width: fit-content;
        margin-bottom: 0.5rem;
    }
    .sidescroll {
        padding: 0 80px;
    }
    .sidescroll > div::-webkit-scrollbar {
        display: none;
    }
    .noticeboard {
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* grid-template-rows: ; */
        /* height: 615px; */
        padding: 0 80px;
        margin: 0.4rem 80px 4rem
    }
    .noticeboard > div {
        grid-column: span 1;
        grid-row: span 1;
        min-height: auto;
    }
    .noticeboard .sop-box {
        grid-column: span 2;
    }
    .noticeboard .sop-box > h2{
        font-size: 3rem;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    .search-body {
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
    .search-body > a, .search-body > div {
        grid-row: span 1;
        grid-column: span 1;
    }
    .search-body > a:hover, .search-body > div:hover {
        transform: scale(1.04,1.04);
    } 
    .user-main {
        margin: 0.4rem auto;
        width: 60%;
    }
    .u-profile .u-brief > p {
        margin: 0.2rem 80px;
    }
    .deets > img {
        width: 400px;
    }
    .u-profile .u-portfolio > .port-wrap {
        width: 50%;
    }
    .cat-grid {
        grid-template-columns: repeat(8,1fr);
        margin: 0.4rem 80px;
    }
    /* .cat-grid > a {} */
  }