.banner {
    position: relative;
}
.banner > img {
    width: 100%;
}
.banner .cta {
    position: absolute;
    top: 0;
    left: 0;
    height: fit-content;
    width: 90%;
    text-align: left;
    margin: auto;
    bottom: 0;
    right: 0;
    color: #fff
}
.banner .cta > h1 {
    font-weight: 600;
    font-size: 1.4rem;
}
.banner .cta > p {
    font-size: 0.9rem;
    width: 290px;
    margin-left: 1.4rem;
}
.banner .cta > div {
    width: 100%;
    padding: 0 0.4rem ;
    margin: 0 auto
}
.banner .cta > div > div input {
    background: #fff;
    font-size: 0.9rem;
}
.banner .cta > div > div input::placeholder {
    color: #aaa;
    font-weight: 200;
}
.banner .cta > div > div:nth-child(2) {
    display: flex;
}
.banner .cta > div > div:nth-child(2) span,.banner .cta > div > div:nth-child(2) > button, .banner .cta > div > div:nth-child(2) > a {
    flex: 1;
    position: relative;
}
.banner .cta > div > div:nth-child(2) > button, .banner .cta > div > div:nth-child(2) > a {
    margin: 0.6rem 0 0 0.6rem
}
.banner .cta > div > div:nth-child(2) > a {
    text-align: center;
    padding: 8px
}
.banner .cta > div > div span svg {
    position: absolute;
    color: #ccc;
    top: 0.5rem;
    bottom: 0;
    right: 0.4rem;
    margin: auto;
    height: 1.2rem;
    width: 1.2rem;
}