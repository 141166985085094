.noticeboard {
    padding: 0.4rem 0;
}
.noticeboard > div {
    background: #fff;
    box-shadow: var(--shadow);
    margin: 0.4rem auto;
    font-size: 1rem;
    min-height: 80px;
    width: 92%;
    padding: 0.4rem;
    text-align: left;
    border-radius: 4px;
}
.noticeboard > .sop-box .sop { 
    /* SOP = Seal of Approval */
    width: 50px;
    display: block;
    margin: 0.4rem 0.8rem 0.4rem 0.4rem;
    box-shadow: var(--shadow);
    border-radius: 50%;
    float: left;
}
.noticeboard > .sop-box > p {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.4rem;
}
.noticeboard > .sop-box > span {
    font-size: 0.9rem;
    font-weight: 300;
    color: #999;
}
.noticeboard > .contact-box {
    padding-bottom: 1.5rem;
    width: 50%;
    position: relative;
}
.noticeboard > .contact-box > img {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    width: fit-content;
    height: 100%;
    margin: auto;
}
.noticeboard > .contact-box > span {
    display: block;
    margin: 1rem;
}
.noticeboard > .contact-box > a {
    padding: 0.6rem;
}
.noticeboard > div > h5 {
    font-size: 1rem;
    color: var(--main);
    font-weight: 400;
    padding: 0 1rem;
}
.noticeboard > div > p {
    font-size: 0.9rem;
    font-weight: 300;
    padding: 0 1rem;
}
.noticeboard > div > img {
    width: 100%;
    border-radius: 4px;
}
.noticeboard > div > button {
    padding: 0.6rem;
    display: block ;
    margin: 0.4rem auto;
}