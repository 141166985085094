/*COLOURS*/
:root {
  --text: #717171;
  --dark: #1a1d26;
  --dark-alt: #20232c;
  --light: #F5F7FB;
  --red: #f55456;
  --green: #25d366;
  --orange: #ffbe76;
  --yellow: #ffe234;
  --main: #99CD71;
  --secondary: #108BF8;
  --highlight: #fa72c9;
  --shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  --shadow-alt: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  --shadow-big: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.dark {
  background: var(--dark);
  color: var(--light);
}
.dark input {
  color: var(--light);
}
.light {
  background: var(--light);
  color: var(--dark);
}
.light input {
  color: var(--dark);
}
.goo {
  cursor: pointer;
}
.goo-l {
  position: absolute;
  top: 0.8rem;
  left: -0.4rem;
  width: 24px;
  background-color: var(--highlight-color);
  border-radius: 0 25px 25px 0 !important;
  cursor: pointer;
  transition: 0.2s;
}
.goo-r {
  position: absolute;
  top: 1.6rem;
  right: 0;
  width: 33px;
  background-color: var(--highlight-color);
  border-radius: 25px 0 0 25px;
  height: 30px;
  z-index: 15;
  transition: 0.2s;
}
.goo > svg {
  z-index: 200;
  position: relative;
  color: #fff;
  margin-left: 0.4rem;
  transition: 0.2s;
}
.goo-r > svg {
  vertical-align: middle;
}
.goo-l:hover {
  width: 35px;
  transition: 0.2s;
  box-shadow: var(--shadow);
}
.goo-l:hover > svg {
  margin-left: 0.6rem;
  transition: 0.2s;
}
.goo-r:hover {
  width: 40px;
  transition: 0.2s;
  box-shadow: var(--shadow);
}
.goo-l:before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -50px;
  left: 0;
  height: 50px;
  width: 15px;
  border-top-left-radius: 25px;
  box-shadow: 0 -22px 0 0 var(--highlight-color);
}
.goo-l:after {
  content: "";
  position: absolute;
  background-color: transparent;
  top: -50px;
  left: 0;
  height: 50px;
  width: 15px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 22px 0 0 var(--highlight-color);
}
.goo-r:before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -47px;
  right: 0;
  height: 50px;
  width: 25px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--highlight-color);
}
.goo-r:after {
  content: "";
  position: absolute;
  background-color: transparent;
  top: -48px;
  right: 0;
  height: 50px;
  width: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0 25px 0 0 var(--highlight-color);
}
.btn {
  background: var(--red);
  color: #fff;
  font-size: 1rem;
  min-width: 50px;
  margin: 0.4rem;
  border-radius: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 3px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;
  text-decoration: none;
}
.btn:hover {
}
.btn:active {
  border-bottom-color: rgba(0, 0, 0, 0);
}
.btn-2 {
  background: var(--main);
  color: #fff;
  font-size: 0.9rem;
  min-width: 50px;
  margin: 0.4rem;
  border-radius: 0.2rem;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  text-decoration: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.btn-simp {
  color: #333;
  font-size: 0.9rem;
  min-width: 50px;
  margin: 0.4rem;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  text-decoration: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
}
.btn-pill {
  border-radius: 50px;
  border: 1px solid var(--text);
  background: #fff;
  color: var(--text);
  font-size: 0.9rem;
  min-width: 50px;
  margin: 0.4rem;
  padding: 0.2rem 0.6rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.6rem;
}
.btn-pill:hover {
  border-color: var(--main);
  background: var(--main);
  color: #fff
}